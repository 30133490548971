<template>
	<div class="content-page">
		<div class="content-nav">
			<el-breadcrumb class="breadcrumb" separator="/">
				<el-breadcrumb-item :to="{ name: 'shipper' }">快递设置</el-breadcrumb-item>
				<el-breadcrumb-item>快递列表</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="operation-nav">
				<el-button type="primary" @click="goBackPage" icon="arrow-left">返回</el-button>
			</div>
		</div>
		<div class="content-main">
			<div class="filter-box">
				<el-form :inline="true" :model="filterForm" class="demo-form-inline">
					<el-form-item label="快递公司">
						<el-input v-model="filterForm.name" placeholder="搜索快递公司"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmitFilter">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="form-table-box">
				<el-table :data="tableData" style="width: 100%" border stripe>
					<el-table-column prop="id" label="ID" width="100px">
					</el-table-column>
					<el-table-column prop="name" label="名字">
					</el-table-column>
					<el-table-column prop="code" label="代号">
					</el-table-column>
					<el-table-column prop="sort_order" label="排序">
					</el-table-column>
					<el-table-column label="操作" width="170">
						<template scope="scope">
							<el-button size="small" @click="handleRowEdit(scope.$index, scope.row)">编辑</el-button>
							<el-button size="small" type="danger" @click="handleRowDelete(scope.$index, scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-box">
				<el-pagination @current-change="handlePageChange" :current-page="page" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			page: 1,
			total: 0,
			filterForm: {
				name: ''
			},
			tableData: []
		}
	},
	methods: {
        goBackPage() {
            this.$router.go(-1);
        },
		handlePageChange(val) {
			this.page = val;
			//保存到localStorage
			localStorage.setItem('shipperPage', this.page)
			localStorage.setItem('shipperFilterForm', JSON.stringify(this.filterForm));
			this.getList()
		},
		handleRowEdit(index, row) {
			this.$router.push({ name: 'shipper_add', query: { id: row._id } })
		},
		handleRowDelete(index, row) {

			this.$confirm('确定要删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.axios.post('setting/deleteShipper', { _id: row._id }).then((response) => {
					console.log(response.data);
					
					if (response.data.code > 0) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});

						this.getList();
					}
				})


			});
		},
		onSubmitFilter() {
			this.page = 1
			this.getList()
		},
		getList() {
			this.axios.get('setting/getShipperList').then((response) => {
				console.log(response.data.data)
                this.tableData = response.data.data;
				this.total = this.tableData.length;

			})
		}
	},
	components: {

	},
	mounted() {
		this.getList();
	}
}

</script>

<style scoped>

</style>
